import React, {useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap'
import './App.css';
import AppStoreBadge from './images/app-store-badge.svg';
import PlayStoreBadge from './images/google-play-badge.svg';
import LocationPin from './images/pin.svg';
import countryData from './json/country.json';
import './bootstrap.min.css';

function App() {
    const [timeLeft, setTimeLeft] = useState("-:-:-");
    const [title, setTitle] = useState("İftara");

    const [country, setCountry] = useState("Türkiye");
    const [city, setCity] = useState("İstanbul");
    const [county, setCounty] = useState("Maltepe");

    const [imsak, setImsak] = useState("-");
    const [gunes, setGunes] = useState("-");
    const [ogle, setOgle] = useState("-");
    const [ikindi, setIkindi] = useState("-");
    const [aksam, setAksam] = useState("-");
    const [yatsi, setYatsi] = useState("-");
    const [modalOpen, setModalOpen] = useState(false);
    const [cityData, setCityData] = useState([]);
    const [countyData, setCountyData] = useState([]);

    const [tempcountry, setTempCountry] = useState("Türkiye");
    const [tempcity, setTempCity] = useState("İstanbul");

    function fetchPrayerTimes(countyId) {
        fetch("https://ezanvakti.herokuapp.com/vakitler/" + countyId).then(async response => {
            if (response.clone().ok) {
                localStorage.setItem('prayerTimes', await response.clone().text());
                setPrayerTimes(await response.clone().text());
            }
        })
    }

    const setRemainingTime = timestamp => {
        const now = new Date().getTime();

        const t = timestamp - now;

        if (t > 0) {
            const hour = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minute = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
            const second = Math.floor((t % (1000 * 60)) / 1000);

            setTimeLeft(("0" + hour).slice(-2) + ":" + ("0" + minute).slice(-2) + ":" + ("0" + second).slice(-2));
        } else {
            setTimeLeft("00:00:00");
        }
    }

    const setPrayerTimes = raw => {
        try {
            const data = JSON.parse(raw);
            let currentDate = new Date();
            let day = (currentDate.getDate() < 10) ?
                "0" + currentDate.getDate() :
                currentDate.getDate();
            let month = ((currentDate.getMonth() + 1) < 10) ?
                "0" + (currentDate.getMonth() + 1) :
                (currentDate.getMonth() + 1);
            let year = currentDate.getFullYear();

            currentDate = day + "." + month + "." + year;
            let index = data.findIndex(d => d.MiladiTarihKisa === currentDate);
            const tomorrowIndex = index + 1;

            let selectedData = data[index];
            let tomorrowData = data[tomorrowIndex];

            setImsak(selectedData.Imsak);
            setGunes(selectedData.Gunes);
            setOgle(selectedData.Ogle);
            setIkindi(selectedData.Ikindi);
            setAksam(selectedData.Aksam);
            setYatsi(selectedData.Yatsi);

            const now = new Date().getTime();

            const dateToday = selectedData.MiladiTarihKisa.split(".");
            const dateTomorrow = tomorrowData.MiladiTarihKisa.split(".");

            const iftarEndDate = new Date(dateToday[1] + "/" + dateToday[0] + "/" + dateToday[2] + " " + selectedData.Aksam);
            const sahurEndDateToday = new Date(dateToday[1] + "/" + dateToday[0] + "/" + dateToday[2] + " " + selectedData.Imsak);
            const sahurEndDateTomorrow = new Date(dateTomorrow[1] + "/" + dateTomorrow[0] + "/" + dateTomorrow[2] + " " + tomorrowData.Imsak);
            const midnightDate = new Date(dateToday[1] + "/" + dateToday[0] + "/" + dateToday[2]+ " 00:00");
            let sahurEndDate;
            let passedMid;

            if(now >= midnightDate.getTime() && now <= sahurEndDateToday) {
                sahurEndDate = sahurEndDateToday.getTime();
                passedMid = true;
            } else {
                sahurEndDate = sahurEndDateTomorrow.getTime();
                passedMid = false;
            }

            if(passedMid === true) {
                setTitle("Sahura");

                setInterval(function() {
                    setRemainingTime(sahurEndDate);
                }, 1000);
            } else {
                if(now >= iftarEndDate.getTime()) {
                    setTitle("Sahura");

                    setInterval(function() {
                        setRemainingTime(sahurEndDate);
                    }, 1000);
                } else {
                    setTitle("İftara");

                    setInterval(function() {
                        setRemainingTime(iftarEndDate.getTime());
                    }, 1000);
                }
            }
        } catch (e) {
            console.log(e);
            reset();
        }
    }

    function fetchCities(item) {
        const countryID = item.target.value;
        setTempCountry(countryData.find(d => d.UlkeID === countryID).UlkeAdi);

        fetch("https://ezanvakti.herokuapp.com/sehirler/" + countryID).then(async response => {
            if (response.ok) {
                setCityData(await response.json());
            }
        })
    }

    function fetchCounties(item) {
        const cityID = item.target.value;

        setTempCity(cityData.find(d => d.SehirID === cityID).SehirAdi);

        fetch("https://ezanvakti.herokuapp.com/ilceler/" + cityID).then(async response => {
            if (response.ok) {
                setCountyData(await response.json());
            }
        })
    }

    function saveLocation(item) {
        const countyID = item.target.value;

        setCounty(countyData.find(d => d.IlceID === countyID).IlceAdi);
        setCountry(tempcountry);
        setCity(tempcity);

        localStorage.clear();

        localStorage.setItem("countyID", countyID);
        localStorage.setItem("countyName", countyData.find(d => d.IlceID === countyID).IlceAdi);
        localStorage.setItem("countryName", tempcountry);
        localStorage.setItem("cityName", tempcity);

        window.location.reload();
    }

    function reset() {
        if(localStorage.getItem('prayerTimes') !== null) {
            localStorage.clear();
        }
    }

    useEffect(() => {
        if(localStorage.getItem('prayerTimes') === null) {
            if(localStorage.getItem("countyID") !== null) {
                fetchPrayerTimes(localStorage.getItem("countyID"));
            } else {
                fetchPrayerTimes(9544);
            }
        } else {
            setPrayerTimes(localStorage.getItem('prayerTimes'));
        }

        if(localStorage.getItem("countyName") !== null && localStorage.getItem("countryName") !== null &&localStorage.getItem("cityName") !== null) {
            setCounty(localStorage.getItem("countyName"));
            setCountry(localStorage.getItem("countryName"));
            setCity(localStorage.getItem("cityName"));
        }
    }, []);

    return (
        <div className="App">
            <header className="AppHeader">
                <div className="TitleHolder">
                    <h1>{title} Ne Kadar Kaldı</h1>
                    <h3>{country} / {city} ({county})</h3>
                </div>
            </header>
            <div className="Main">
                <div className="Container">
                    <div className="LocationSelectHolder">
                        <div className="LocationSelectButton" onClick={() => setModalOpen(true)}>
                            <img src={LocationPin}/> Konum Seç
                        </div>
                    </div>
                    <div className="Box">
                        <div className="MainCounter">
                            {timeLeft}
                        </div>
                    </div>
                    <div className="PrayerTimesContainer">
                        <div className="PrayerTimesBox">
                            <div className="PrayerTimesTitle">İmsak</div>
                            <div className="PrayerTimesTime">{imsak}</div>
                        </div>
                        <div className="Spacer"/>
                        <div className="PrayerTimesBox">
                            <div className="PrayerTimesTitle">Güneş</div>
                            <div className="PrayerTimesTime">{gunes}</div>
                        </div>
                        <div className="Spacer"/>
                        <div className="PrayerTimesBox">
                            <div className="PrayerTimesTitle">Öğle</div>
                            <div className="PrayerTimesTime">{ogle}</div>
                        </div>
                        <div className="Spacer"/>
                        <div className="PrayerTimesBox">
                            <div className="PrayerTimesTitle">İkindi</div>
                            <div className="PrayerTimesTime">{ikindi}</div>
                        </div>
                        <div className="Spacer"/>
                        <div className="PrayerTimesBox">
                            <div className="PrayerTimesTitle">Akşam</div>
                            <div className="PrayerTimesTime">{aksam}</div>
                        </div>
                        <div className="Spacer"/>
                        <div className="PrayerTimesBox">
                            <div className="PrayerTimesTitle">Yatsı</div>
                            <div className="PrayerTimesTime">{yatsi}</div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="Footer">
                <div className="AppStore">
                    <a href="https://apps.apple.com/us/app/i-ftar-sayac%C4%B1-sahur-sayac%C4%B1/id1617267118" target="_blank">
                        <img alt="Apple App Store" src={AppStoreBadge} className="StoreBadge" style={{marginRight: 10}}/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.zapps.iftarsayaci" target="_blank">
                        <img alt="Google Play Store" src={PlayStoreBadge} className="StoreBadge"/>
                    </a>
                </div>
                <div>&#169; iftaranekadarkaldi.net 2022</div>
                <div className="BottomLink"><a href="/privacy.html">Gizlilik Politikası</a> | <a href="mailto:zelyurtberkay@gmail.com">İletişim</a></div>
            </footer>
            <Modal
                show={modalOpen}
                onHide={() => setModalOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Konum Seç</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <select className="form-control" onChange={item => fetchCities(item)}>
                        <option selected>Ülke Seçin</option>
                        {countryData.map((country, i) => (
                            <option value={country.UlkeID}>{country.UlkeAdi}</option>
                        ))}
                    </select>
                    <br/>
                    <select className="form-control" onChange={item => fetchCounties(item)}>
                        <option selected>Şehir Seçin</option>
                        {cityData.map((city, i) => (
                            <option value={city.SehirID}>{city.SehirAdi}</option>
                        ))}
                    </select>
                    <br/>
                    <select className="form-control" onChange={item => saveLocation(item)}>
                        <option selected>İlçe Seçin</option>
                        {countyData.map((county, i) => (
                            <option value={county.IlceID}>{county.IlceAdi}</option>
                        ))}
                    </select>
                </Modal.Body>
            </Modal>
        </div>

    );
}

export default App;
